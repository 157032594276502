import React from 'react'

function Contribute() {
  return (
    <div className='px-4 md:px-16  bg-[#0ecece] text-center text-white py-8 grid' id='contribute'>
        <h3 className='text-center text-xl font-bold py-4'>Contitribute</h3>

        <table className='md:text-xl md:font-bold text-left md:w-2/3 lg:w-2/4 xl:w-1/3 w-full px-4 justify-self-center'>
          <tbody>
            <tr>
              <td>Bank Name</td> 
              <td>Tyme Bank </td>
            </tr>
            <tr>
              <td>Name of Account</td>
              <td>EXCELLENCE</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>53001684817</td>
            </tr>
            <tr>
              <td>Branch Code</td>
              <td>678910</td>
            </tr>
          </tbody>
        </table>
    </div>
  )
}

export default Contribute