import React from 'react'
import { Burger,Cross } from './Icons'
import scale_logo from '../assets/scale_logo.png'
import { Link } from 'react-router-dom'



const Header = ({setIsMenu, isMenu}) => {

    
  return (
    <header className='flex px-4 py-2 shadow-xl justify-between md:sticky md:top-0  z-50 md:z-50 md:bg-white'>

      <div className="md:justify-self-start">
        <Link to='/'><img src={scale_logo} alt='logo' className="w-20 md:w-32"/></Link>
      </div>



      <div className="pb-4 md:gap-10 md:self-center font-semibold">
        <div className="flex">
          <a className='px-4 text-xl hidden md:block hover:text-slate-400' href='/#contribute'>Contribute</a>
          <a className='px-4 text-xl hidden md:block hover:text-slate-400' href='/#about'>About</a>
          <a className='px-4 text-xl hidden md:block hover:text-slate-400' href='/#team'>Team</a>
          <a className='px-4 text-xl hidden md:block hover:text-slate-400' href='/#events'>Events</a>
        </div>
        <div className="md:hidden pt-4">
         <button onClick={()=> setIsMenu(!isMenu)}> {!isMenu? <Burger /> : <Cross /> } </button> 
        </div>
      </div>
    </header>
  )
}

export default Header