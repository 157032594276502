import React from 'react'

const Footer = () => {
  return (
    <div className='px-4 py-6 bg-[#c46bc4] text-white lg:grid lg:grid-cols-3 '>
        
        <div className="flex-col">
          <h3 className='text-lg font-bold'>Contact Us</h3>
            <p>076 514 2492 </p>
            <p>excellencewhf@gmail.com</p>
        </div>
       
        <div className="">
          <p>Reg No:2022/49551/08 </p>
          <p>Copyright 2023 EXCELLENCE HEALTH FOUNDATION All Rights Reserved</p>
          <p> Site By Bongani Ntshangase @ Astro Harpy </p>
        </div>
         <div className="">
          
        </div>
    </div>
  )
}

export default Footer