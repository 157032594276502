import React from 'react'

function Menu({setIsMenu}) {
    const handleToggle = () =>{
        setIsMenu(false)
    }
  return (
    <div className="grid md:hidden w-full">
        <div className='md:hidden text-white font-semibold bg-[#0ecece] w-4/5 text-center absolute top-22 z-40 py-8 justify-self-center'>
            <a className='px-6 text-lg block' href='/#contribute' onClick={handleToggle}>Contribute</a>
            <a className='px-6 text-lg block' href='/#about' onClick={handleToggle}>About</a>
            <a className='px-6 text-lg block' href='/#team' onClick={handleToggle}>Team</a>
            <a className='px-6 text-lg block' href='/#events' onClick={handleToggle}>Events</a>
        
        </div>
    </div>
  )
}

export default Menu