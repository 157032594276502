import React from 'react'
import { Link } from 'react-router-dom'

function Events() {
  return (
    <div className='px-4  bg-[#8b7ab0]  py-10 my-0 text-white' id='events'>

      <h1 className='text-center text-2xl font-bold my-4'>Events</h1>
      <h2 className='text-center my-2 font-bold text-lg'>Upcoming Events</h2>
      <div className="">
       <p className='text-center'>No Event Currently Scheduled</p>
      </div>

      <h2 className='text-center mt-4 mb-2 font-bold text-lg'>Past Events</h2>
      <div className="border-y py-2">
        <h2 className='text-center text-md font-bold'>The Gala Dinner</h2>
        <p className='text-justify mt-2 md:text-center'> 
          The 20th of May 2023 marks a year from registration. This also happens to be the founder’s and directors 40th
          birthday, she has decided her birthday to fundraising for the foundation.

        
        </p> 
         <div className="grid content mt-4">
            <Link className='bg-[#0ecece] block my-2 py-2 w-1/2  md:w-1/5 justify-self-center text-white font-bold text-center' to='/events/gala dinner'>Read More</Link>
          </div>
      </div>
    </div>
  )
}

export default Events