


export const Cross = (props) =>{
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6 text-black">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
  
}

export const Burger = () =>{
    return <svg xmlns="http://www.w3.org/2000/svg" 
    fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" 
    className="w-8 h-8">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
  </svg>  
}


