import React from 'react'

function EventItem() {
  return (
    <section className='min-h-[80vh]'>
        <h1 className='text-xl text-center my-2 md:text-3xl font-bold '>The Gala Dinner</h1>
        
        <div className=" px-4 grid gap-2 md:gap-0">
        <p className=''> 
            The 20th of May 2023 marks a year from registration. This also happens to be the founder’s and directors 40th
            birthday, she has decided her birthday to fundraising for the foundation. We need your support in making that
            happen.
        </p>
        <p>
            When you buy the ticket proceeds from the gala will go towards education and awareness campaigns which will
            encourage going to health facilities to do cancer screenings. These awareness campaigns are aimed at increasing
            the chances of early cancer diagnosis.
        </p>
        <p>
            Detecting cancer at an early stage means treatment is more likely to be successful and prevents needless deaths
            caused by spotting the cancer in its late stages. So by buying a ticket you will be helping in saving lives. Monetary
            donations are also welcome and not limited to the ticket sales only.
        </p>
        </div>

        <h2 className='text-center my-2 text-xl md:text-3xl font-bold '>Post Gala Pictures</h2>

        <div className="grid md:grid-cols-10 px-4 gap-y-1 md:grid-rows-2 md:gap-2 my-2">
            <img className='md:col-start-4 md:col-span-10' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/gala/group1.jpeg" alt="" />
            <img className='md:col-span-7'  src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/gala/group2.jpeg" alt="" />
            <img className='md:row-start-1 md:col-span-3'  src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/gala/mpanzas.jpeg" alt="" />
            <img className='md:col-start-8 md:col-span-3'  src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/gala/ndumi.jpeg" alt="" />
        </div>
        
 
    </section>
  )
}

export default EventItem