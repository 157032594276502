

function Objectives() {
  return (
    <section className="md:bg-purple-600 md:h-screen" id="about">
      <h1 className="md:text-4xl text-center font-bold md:pt-8 md:text-white text-xl">About Our Organization</h1>
    <div className='px-4 grid md:grid-cols-3 md:h-full md:place-content-center lg:gap-x-4'>

<div className="md:px-2 md:mx-2  lg:mx-2 xl:w-4/5 lg:w-full justify-self-center text-center p-8 my-2 bg-[#e07be0] md:bg-white md:text-black text-white rounded-lg">  
    <div className='grid mt-2'><img className="justify-self-center" src="./images/journey.png" alt="" /></div>

    <h1 className='text-center  text-xl font-bold my-4'>Our Story</h1>

    <p className='text-justify md:text-center text-sm lg:text-sm '> 
      The Excellence Health Foundation was founded and registered in May 2022. The organisation focuses on women’s
      health education in general. Most of our work focuses is on raising awareness on the top 5 cancers affecting
      women in KZN. According to the National Cancer Registry: cervical, breast, colorectal, vulva and other uterine
      cancers are the top cancers affecting women in KZN. We have been doing educational talks at a smaller scale on
      various social media platforms and now want to expand to community’s dialogues and awareness campaigns.
    </p>

  </div>

<div className="md:px-2 md:mx-2  lg:mx-2 xl:w-4/5 lg:w-full justify-self-center text-center   md:bg-white bg-[#0ecece] p-8  my-2 rounded-lg md:text-black text-white">

  <div className='grid mt-2'><img className="justify-self-center" src="./images/target.png" alt="" /></div>

  <h2 className='text-center  text-xl font-bold my-4 '>Organizational Objectives</h2>
  <p className='text-justify md:text-center text-sm lg:text-sm '>
    We want to raise awareness on early detection and prevention strategies for the cancers mentioned
    above. <br />
    We are also planning to train and equip community, traditional and faith based leaders to conduct
    community awareness campaigns and support of patients suffering from cancer in their communities.
  </p>
</div>

<div className="md:px-2 md:mx-2  lg:mx-2 xl:w-4/5 lg:w-full justify-self-center text-center md:bg-white bg-[#bba3ee] p-8  my-2 rounded-lg md:text-black text-white">
  <div className='grid mt-2'><img className="justify-self-center" src="./images/map.png" alt="" /></div>

  <h2 className='text-center  text-xl font-bold my-4'>Target Areas</h2>

  <p className='text-justify md:text-center text-sm lg:text-sm '>
    UMkhanyakude district and some rural and disadvantaged communities surrounding Durban.<br />
    Umkhanyakude district is largely rural, the whole district does not have facilities and expertise for
    treatment of cancer.<br /> Patients from this area have to travel long distances and spend days away from
    home to access oncology centres. Health awareness strategies will benefit them significantly and save
    many lives.
  </p>
</div>
    </div>
    </section>
  )
}

export default Objectives