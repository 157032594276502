import React from 'react'

const Team = () => {
  return (
  <div className='px-4 md:px-16 md:my-0 mt-4 bg-[#c46bc4] md:grid md:h-screen py-4 ' id="team">

    <h2 className='text-center text-xl font-bold my-2 text-white '> Our Team</h2>

    <div className="grid grid-cols-2 md:grid-cols-2 gap-4 text-center text-sm md:text-base xl:w-4/5 xl:justify-self-center">

    <div className="md:grid-cols-2 md:grid">
      <img className='rounded-full  mt-4 mb-2' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation//pam.jpeg" alt="Dr Ntshangase-Mpanza" />
    
      <div className="md:justify-self-start md:self-center md:ml-4 xl:ml-10">
        <p className='text-white font-bold md:text-lg lg:text-xl'>Dr Ntshangase-Mpanza </p>
        <p className='text-center md:text-xl text-white'>Founder and director </p>
      </div>

    </div>

    <div className="md:grid-cols-2 md:grid">
      
      <img className='rounded-full  mt-4 mb-2 ' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/nondumiso.jpeg" alt="Nondumiso Ntshangase core director and legal" />
      
      <div className="md:justify-self-start md:self-center md:ml-4 xl:ml-10">
        <p className='text-white font-bold md:text-lg lg:text-xl'>Nondumiso Ntshangase </p>
        <p className='text-center md:text-xl text-white'>Co director and legal</p>
      </div>

    </div>

    <div className="md:grid-cols-2 md:grid">

      <img className='rounded-full  mt-4 mb-2' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/gugu.jpeg" alt=" Gugu Mpanza core director and amin" />
    
      <div className="md:justify-self-start md:self-center md:ml-4 xl:ml-10">
        <p className='text-white font-bold md:text-lg lg:text-xl'>Gugu Mpanza</p>
        <p className='text-center md:text-xl text-white'>Co director and admin</p>
      </div>

    </div>

    <div className="md:grid-cols-2 md:grid">
      <img className='rounded-full  mt-4 mb-2' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/tr:q-40/zwakele.jpg" alt="Zwakele Mpanza finance and compliance" />
    
      <div className="md:justify-self-start md:self-center md:ml-4 xl:ml-10">
        <p className='text-white font-bold md:text-lg lg:text-xl'>Zwakele Mpanza</p> 
        <p className='text-center md:text-xl text-white'>Finance and compliance</p>
      </div>
      
    </div>

    </div>
  </div>
  )
}

export default Team