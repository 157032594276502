import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='px-4 md:h-[80vh] h-[95vh] w-full  md:grid md:grid-cols-2' >
      <div className="z-20">

          <h1 className='text-sm md:text-2xl py-10 font-semibold border-b'>WELCOME TO <span className='text-purple-600'>EXCELLENCE HEALTH FOUNDATION</span></h1>
          <h2 className='text-xl sm:text-3xl font-bold leading-8 text-left my-6 lg:text-6xl  md:font-normal md:w-3/4 whitespace-nowrap md:text-2xl' >
              All About Women’s Health
          </h2>
          <p className='md:text-lg md:my-6'>
          We are an organisation dedicated to women's health education, with a focus on cancer awareness and prevention strategies.
          </p>
        <a className='px-8 py-2 bg-[#0ecece] text-white md:w-2/5 md:text-center text-lg font-bold md:text-2xl inline-block my-4' href='/#contribute' >Contribute</a> 
       </div>
       
       <img className='md:w-9/12 justify-end' src="https://ik.imagekit.io/fldpkglpn/Execellence%20Health%20Foundation/hero.jpeg" alt="Dr Ntshangase-Mpanza" />
    </div>
  )

}

export default Hero 