import React, {useState} from 'react'
import {Routes, Route} from "react-router-dom";
import Header from "./components/Header";
import Objectives from './components/Objectives';
import Team from './components/Team';
import Events from './components/Events';
import Home from './components/Home';
import Footer from './components/Footer';
import Contribute from './components/Contribute';
import Menu from './components/Menu';
import EventItem from './components/EventItem';



function App() {
  const [isMenu, setIsMenu] = useState(false) 


  return (
    <div className="relative text-sm md:text-base"> 
      <Header isMenu={isMenu} setIsMenu={setIsMenu}/>

      {isMenu? <Menu isMenu={isMenu} setIsMenu={setIsMenu} /> : null}
      <Routes>
        <Route path='/' element= {
          <>
            <Home />
            <Objectives />
            <Team />
            <Events />
            <Contribute />
          </>
          }
        />
        
        <Route path='/events/:id' element={<EventItem />}/>
      </Routes>

      <Footer />
    </div>
  )
}

export default App;
